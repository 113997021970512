@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');



/*  Testa denna font? */
/* @import url('https://fonts.googleapis.com/css2?family=Ysabeau+SC:wght@1&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/*  Testa denna font?  font-family: 'Ysabeau SC', sans-serif; */

:root {

    /* Image Frame */
    --color-imgbg: #dddddd;
    --color-details: #38ab26;

    /* ThemeColor */
    --color-primary: #d7a33c;
    --color-secondary: #E75A7C;
    --color-light: #ffffff;
    --font-primary: 'Montserrat', sans-serif;
    --swiper-theme-color: #5f8f76 !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
    /* background-color: black;   */   
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.colortest {
    color:#282828
}
html, body {overflow-x: hidden !important;width: 100vw !important;}
.flex {
    display: flex !important;
}
.flex__center-r {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.flex__center-c {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.flex-c {
    display: flex !important;    
    flex-direction: column;
}
.flex__r-c {

}

@media screen and (max-width: 650px) {
    .flex__r-c {
        flex-direction: column;
    }
}

.objectfitcontain {
    object-fit: contain;
}

.header-img {
    /* filter: brightness(0.5); */
    filter: blur(5px);
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
}
.header-img-noblur {
    filter: brightness(0.5);    
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100%;
    z-index: -1;
    transform: translate3d(0,0,0);  
}
.menu-img-container {  
   /*  top: 0; left: 0; right: 0; bottom: 0;
    position: absolute; */
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: translate3d(0,0,0);  
}

h1,h2,h3,h4,h5,h6,p,button,a {
    white-space: pre-line !important;
    font-family: var(--font-primary) !important;
}

.header-img-fade {

}
.header-img-fade::before {
    z-index: 1;
    content: '';
    display: block;
    left: 0; right: 0; bottom: 0;
    width: 100vw;
    height: 20%;
    position: absolute;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgb(1, 1, 1));  
}
.header-img-fade::after {
    z-index: 1;
    content: '';
    display: block;
    left: 0; right: 0; top: 0;
    width: 100vw;
    height: 20%;
    position: absolute;
    background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(0, 0, 0));  
}



/** Scrollbar  */
/* width */
::-webkit-scrollbar {
    width: 10px;
}  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}   
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}





/** Footer Navigation buttons */
.footer__button{
    /* font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300; */
    
    color: #bbbbbb !important;
    /* display: block; */
    transition: all 0.3s ease;
}
.footer__button:hover{
color: #ffffff !important;
transform: translateX(8px);
transition: all 0.3s ease;
}






/** BG ICON */
.container__icon {    
    position: absolute;
    width: 100vw !important; height: 100%;
    max-width: auto;
    z-index: -1;
    background-color: var(--color-primary);
    
}
.container__icon-svg{
    width: 100vw; height: 100vh;       
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    /* filter: url(#gaussian-blur);    */
}
.container__icon  path{
    fill: #5f8f7678 !important;
  
    
}



/** Simple icon, no bg */
.container__icon-nobg {    
    position: absolute;
    width: 100% !important; height: 100%;
    max-width: auto;
    z-index: -1;
    
    
}
.container__icon-svg-nobg{
    /* width: 100%; height: 100%;        */
    /* filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)) */
    /* filter: url(#gaussian-blur);    */
}
.container__icon-svg-nobg path {
    fill: var(--color-primary) !important;
}
.container__icon-nobg  path{
    fill: #48b85b78 !important;      
}


/** Simple icon 2 position: relative */
.container__icon-nobg-2 {    
    /* position: absolute; */
    width: 50px; height: 50px;
    max-width: auto;
    /* z-index: -1; */
    
    
}
.container__icon-svg-nobg-2 {
    width: 50px !important; height: 50px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    /* filter: url(#gaussian-blur);    */
}
.container__icon-svg-nobg-2 path {
    fill: var(--color-light) !important;
}
.container__icon-nobg-2  path{
    fill: #48b85b78 !important;      
}





/** Mobile Navbar  */
.navbar-small {        
    position: fixed;
    display: none;
    top: 0;    
    right: 0;    
    width: 100vw;    
    height: 100vh;
    z-index: 4;
    transition: all 0.5s ease-in-out;  
    gap: 1rem;          
    flex-wrap: wrap;
    touch-action: none;

}





/** NAVBAR Buttons*/
.navbar__navigation {    
    height: 100%;
    gap: 0.5rem;
    
}
.navbar__navigation-buttons {    
    height: 100%;
    position: relative;
    
    
}
.navbar__navigation-buttons button {
    border: none;
    background-color: rgba(255, 0, 0, 0);
    height: 100%;
    min-width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: var(--color-light);
}
.navbar-line{
    position: absolute;
    bottom: -5px; 
    width: 0%;
    height: 2px;
    opacity: 0;
    border-radius: 50%;
    background-color: var(--color-primary);    
    transition: all 0.2s ease-in-out;
}
.navbar__navigation-buttons:hover .navbar-line{    
    width: 95%;    
    opacity: 1;
}
.navbar__navigation-buttons:hover button{    
    color:  var(--color-primary);
}
.navbar__navigation-buttons:hover *{    
    color:  var(--color-primary);
}



/** Square Button*/
.navbar__navigation-sqrbtn{
    height: 100%;
}
.navbar__navigation-sqrbtn button{
background: none;
border: none;
cursor: pointer;


padding: 0.2rem 0.6rem 0.2rem 0.6rem;
letter-spacing: 0.05rem;
}
.navbar__navigation-sqrbtn .square-btn {
box-shadow: inset 0 0 0 2px #f5f5f5;
color: #f7f7f7;
transition: color 0.25s 0.0833333333s;
position: relative;
}
.navbar__navigation-sqrbtn .square-btn::before, .navbar__navigation-sqrbtn .square-btn::after {
border: 0 solid transparent;
box-sizing: border-box;
content: '';
pointer-events: none;
position: absolute;
width: 0;
height: 0;
bottom: 0;
right: 0;
}
.navbar__navigation-sqrbtn .square-btn::before {
border-bottom-width: 3px;
border-left-width: 3px;
}
.navbar__navigation-sqrbtn .square-btn::after {
border-top-width: 3px;
border-right-width: 3px;
}

.navbar__navigation-sqrbtn .square-btn:hover {
color: var(--color-primary) !important;
}
.navbar__navigation-sqrbtn .square-btn:hover::before, .navbar__navigation-sqrbtn .square-btn:hover::after {
border-color: var(--color-hover);
transition: border-color 0s, width 0.25s, height 0.25s;
width: 100%;
height: 100%;
}
.navbar__navigation-sqrbtn .square-btn:hover::before {
transition-delay: 0s, 0s, 0.25s;
}
.navbar__navigation-sqrbtn .square-btn:hover::after {
transition-delay: 0s, 0.25s, 0s;
}
@media screen and (max-width: 920px){      
    .navbar__navigation-sqrbtn button,
    .navbar__navigation-buttons button {
        min-width: 80px;
        font-size: 15px;
    }       
}
@media screen and (max-width: 700px){  
    .navbar__navigation-buttons button {
        min-width: 60px;
        font-size: 10px;
    }
    .navbar__navigation-sqrbtn button {
        width: 50px;
        font-size: 10px;
    }     
}







/** Image Frame 1  */
.heading-frame {    
	display: grid;
	box-shadow: 0 3px 6px rgba(0,0,0,0.4);
	grid-template-columns: max-content auto;
	grid-template-rows: auto max-content;
	background: #333 url(https://images.unsplash.com/photo-1497406703182-f805b8fbba89?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7598c4ba7994f83dab58ae34f1abd023&auto=format&fit=crop&w=1584&q=80) center;
	background-size: cover;
	color: white;
	width: 400px;
	height: 300px;
	padding: 2rem;
    
}
.heading-frame::before,
.heading-frame::after {
	
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		border: 1px solid white;
}
.heading-frame::before {
    grid-row: 1 / 2;
    border-right: none;
    border-bottom: none;
    
}
.heading-frame::after {
    grid-row: 1 / span 2;
    border-left: none;
    
}
.heading-frame h2 {
    margin: 1rem 1rem -.5rem 0;
    font-family: 'Pragati Narrow', sans-serif;
    font-size: 2rem;
    max-width: 100%;
}










/**  SWIPER IMG */
 .swiper-img{  
    object-fit: cover;
    height: 700px !important;
    width: 80vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    max-height: 80vh;
  
    
}

.swiper {
    max-width: 90vw;
    height: 100%;
    cursor: pointer; 
}

.swiper-slide {
    

    display: flex !important;
    justify-content: center;
    align-items: center;
   
}
/*  
.swiper-wrapper {
    max-width: 90vw;
}   */
@media screen and (max-width: 650px){
    .swiper-img{
        height: 50% !important;
    }
}
  




/** HAMBURGER MENU*/
.navbar-small__icon span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #000000;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}
.navbar-small__icon span:nth-last-child(1){    
    transform-origin: 0% 100%;
    /* transform: rotate(-45deg) translate(0,1px) */   
}









/** WRAP ROWS  */
.wrap-3-row {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;    
}  
.wrap-1-row {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;    
}  
.singlerow-wrap {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 10px;
  } 




/** EVENT IMAGE  */
.event-image {
   /*  object-fit: contain; */
    /* max-height: 500px; */
    /* overflow: hidden; */
 
    width: calc(100vw / 3);
 
}
@media screen and (max-width:900px) {
    .event-image {
    /*  object-fit: contain; */
        /* max-height: 500px; */
        /* overflow: hidden; */
        max-width: calc(95vw / 2) !important;
    }
    .event-image img{ 
        max-height: 400px !important;
    }
}
@media screen and (max-width:600px) {
    .event-image {
    /*  object-fit: contain; */
        /* max-height: 500px; */
        /* overflow: hidden; */
        max-width: 95vw !important;
    }
    .event-image img{ 
        width: 80vw !important;
    }
}
.event-image-box {
  /*   min-height: 100%; */
    /* max-height: 700px; */
}
.event-image img{
    float: left;
   
    width: calc(90vw / 3);
    max-width: 400px;
    height: 80vh;
    max-height: 500px;
    object-fit: cover;
   /*  overflow: hidden; */
}



/** Image Frame */

@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
figure.snip1113 {
    font-family: 'Raleway', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;
    min-width: 220px;
    max-width: 600px;
    width: 100%;
    background: var(--color-imgbg);
    text-align: center;
    box-shadow:rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  
  figure.snip1113 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  figure.snip1113 img {
    max-width: 65%;
    margin: 40px auto;
    display: block;
    position: relative;
    border: 3px solid #666666;
    padding: 15px 15px 85px 15px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  
  figure.snip1113 figcaption {
    position: absolute;
    height: 120px;
    bottom: 0px;
    left: 0;
    right: 0;
    display: block;
  }
  
  figure.snip1113 h3 {
    background-color: var(--color-imgbg);
    color: #000000;
    font-size: 1.7em;
    width: 100%;
    padding: 5px 12px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 400;
  }
  
  figure.snip1113 h3 span {
    font-weight: 800;
  }
  
  figure.snip1113 h4 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: -1px;
    opacity: 0.6;
    font-size: 1.1em;
  }
  
  


figure.snip1113.red img { /* RECT BORDER AROUND IMAGE */
    border-color: var(--color-details);
}
figure.snip1113.red h3,
figure.snip1113.red h4 { /* TextColors */
    color: var(--color-details);
}

@media screen and (max-width: 650px) {
    figure.snip1113 h3 {
        font-size: 1.2em;
    }
    figure.snip1113 h4 {
        font-size: 0.8em;
    }
}









.fade-appear,
.fade-enter {
opacity: 0;
z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
opacity: 1;
transition: opacity 450ms linear 250ms;
}
.fade-exit {
opacity: 1;
}
.fade-exit.fade-exit-active {
opacity: 0;
transition: opacity 150ms linear;
}